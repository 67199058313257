var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-license')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',[_c('label',{attrs:{"for":"input-multi"}},[_vm._v("Email "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                                    'is-invalid': _vm.submitted && _vm.$v.form.email.$error,
                                },attrs:{"id":"input-3","placeholder":"Enter Email","autocomplete":"new-email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(!_vm.$v.form.email.email)?_c('div',{staticClass:"error"},[_c('span',[_vm._v("Please Enter Valid Email.")])]):_vm._e(),(_vm.submitted && _vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.email.required)?_c('span',[_vm._v("Enter Email.")]):_vm._e()]):_vm._e()],1),((_vm.$route.name == 'add-license'))?_c('b-form-group',[_c('label',{attrs:{"for":"input-3"}},[_vm._v("Password "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                                    'is-invalid': _vm.submitted && _vm.$v.form.password.$error,
                                },attrs:{"id":"input-3","type":"password","placeholder":"Enter Password","autocomplete":"new-password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),(_vm.submitted && !_vm.$v.form.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Enter Password ")]):_vm._e()],1):_vm._e(),_c('b-form-group',[_c('label',[_vm._v("Role "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-select',{class:{
                                    'is-invalid': _vm.submitted && _vm.$v.form.role_id.$error,
                                },attrs:{"id":"input-multi","options":_vm.getForumRole,"value-field":"id","text-field":"name"},model:{value:(_vm.form.role_id),callback:function ($$v) {_vm.$set(_vm.form, "role_id", $$v)},expression:"form.role_id"}}),(_vm.submitted && !_vm.$v.form.role_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Role is required ")]):_vm._e()],1),_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}},[_vm._v("Is Active")])],1),(_vm.$route.name == 'edit-license')?_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.updateLicense(_vm.$route.params.id)}}},[_vm._v("Update Data")]):(_vm.$route.name == 'add-license')?_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitLicenseData.apply(null, arguments)}}},[_vm._v("Save Data")]):_vm._e()],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }