<template>
    <Layout>
        <template v-if="this.$route.name == 'add-license'">
            <PageHeader :title="title1" :items="items"></PageHeader>
        </template>
        <template v-else>
            <PageHeader :title="title2" :items="items"></PageHeader>
        </template>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-form enctype="multipart/form-data">
                            <b-form-group>
                                <label for="input-multi">Email <span style="color: red;">*</span></label>
                                <b-form-input id="input-3" v-model="form.email" placeholder="Enter Email"
                                    autocomplete="new-email" :class="{
                                        'is-invalid': submitted && $v.form.email.$error,
                                    }">
                                </b-form-input>
                                <div class="error" v-if="!$v.form.email.email">
                                    <span>Please Enter Valid Email.</span>
                                </div>
                                <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                                    <span v-if="!$v.form.email.required">Enter Email.</span>
                                </div>
                            </b-form-group>
                            <b-form-group v-if="($route.name == 'add-license')">
                                <label for="input-3">Password <span style="color: red;">*</span></label>
                                <b-form-input id="input-3" v-model="form.password" type="password"
                                    placeholder="Enter Password" autocomplete="new-password" :class="{
                                        'is-invalid': submitted && $v.form.password.$error,
                                    }">
                                </b-form-input>
                                <div v-if="submitted && !$v.form.password.required" class="invalid-feedback">
                                    Enter Password
                                </div>
                            </b-form-group>
                            <b-form-group>
                                <label>Role <span style="color: red;">*</span></label>
                                <b-form-select id="input-multi" v-model="form.role_id" :options="getForumRole"
                                    value-field="id" text-field="name" :class="{
                                        'is-invalid': submitted && $v.form.role_id.$error,
                                    }">
                                </b-form-select>
                                <div v-if="submitted && !$v.form.role_id.required" class="invalid-feedback">
                                    Role is required
                                </div>
                            </b-form-group>
                            <b-form-group>
                                <b-form-checkbox v-model="form.is_active">Is Active</b-form-checkbox>
                            </b-form-group>
                            <b-button type="submit" variant="primary" class="mr-2" v-if="$route.name == 'edit-license'"
                                @click.prevent="updateLicense($route.params.id)">Update Data</b-button>
                            <b-button type="submit" variant="primary" class="mr-2" v-else-if="$route.name == 'add-license'"
                                @click.prevent="submitLicenseData">Save Data</b-button>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import forumToolLicenseMixin from "../../../../mixins/ModuleJs/forum-license";
import { required, email } from "vuelidate/lib/validators";

export default {
    mixins: [MixinRequest, forumToolLicenseMixin],
    data() {
        return {
            options: [
                {
                    key: "dashboard",
                    display: "Dashboard",
                    selected: false,
                    values: [],
                },
                {
                    key: "campaigns",
                    display: "Campaigns",
                    selected: false,
                    // values : ['Create Campaign', 'Edit Campaign List', 'View Campaigns'],
                    values: [
                        {
                            key: "create_campaign",
                            display: "Create Campaign",
                            selected: false,
                        },
                        {
                            key: "edit_campaign",
                            display: "Edit Campaign List",
                            selected: false,
                        },
                        {
                            key: "view_campaign",
                            display: "View Campaign",
                            selected: false,
                        }
                    ]
                },
                {
                    key: 'my_audience',
                    display: "My Audience",
                    selected: false,
                    // values : ['View Audience','Create Audience', 'Edit Audience List'],
                    values: [
                        {
                            key: "create_audience",
                            display: "Create Audience",
                            selected: false,
                        },
                        {
                            key: "edit_audience",
                            display: "Edit Audience List",
                            selected: false,
                        },
                        {
                            key: "view_audience",
                            display: "View Audience",
                            selected: false,
                        }
                    ]
                },
                // { \\
                //     key : "My Content",
                //     selected : false,
                //     values : ['View Content', 'Create Content','Latest', 'Most Viewed']
                // },
                {
                    key: "analytics",
                    display: "Analytics",
                    selected: false,
                    values: []
                }
            ],
            selected: [],
            submitted: false,
            title1: "Add License",
            title2: "Edit License",
            items: [
                {
                    text: "Back",
                    href: "/forum-settings/" + this.$route.params.id + "/" + this.$route.params.forum_id,
                },
                {
                    text: "Data",
                },
            ],
        };
    },
    validations: {
        form: {
            email: { required, email },
            password: { required },
            role_id: { required }
        },
    },
    components: {
        Layout,
        PageHeader,
    },
};
</script>